import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "../src/components/Footer/Footer";
import Popup from "./components/Popup/Popup";
import Loader from "./components/Loader/Loader"; // Import the Loader component
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";

// Lazy load all page components
const Home = lazy(() => import("./Pages/Home"));
const Xd = lazy(() => import("./Pages/XD/XD"));
const CA = lazy(() => import("./Pages/CA/CA"));
const We = lazy(() => import("../src/Pages/WE/We"));
const W3 = lazy(() => import("../src/Pages/W3/w3"));
const DC = lazy(() => import("../src/Pages/DC/Dc"));
const Error404 = lazy(() => import("./Pages/404page/error404"));
const Banking = lazy(() => import("./Pages/Industries/Banking/Banking"));
const Technologies = lazy(() => import("./Pages/Technologies/Technologies"));
const HealthFitness = lazy(() =>
  import("./Pages/Industries/HealthFitness/HealthFitness")
);
const MobileApps = lazy(() =>
  import("./Pages/Technologies/MobileApps/MobileApps")
);
const Frontend = lazy(() => import("./Pages/Technologies/FrontEnd/FrontEnd"));
const BackendOfferings = lazy(() =>
  import("./Pages/Technologies/BackendOfferings/BackendOfferings")
);
const Cloudservices = lazy(() =>
  import("./Pages/Technologies/CloudServices/CloudServices")
);
const AppPlatforms = lazy(() =>
  import("./Pages/Technologies/AppPlatforms/AppPlatforms")
);
const EcomQcom = lazy(() => import("./Pages/Industries/EcomQcom/EcoQcom"));
const Entertainment = lazy(() =>
  import("./Pages/Industries/Entertainment/Entertainment ")
);
const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));
const StudiosPage = lazy(() => import("./Pages/StudiosMain/StudiosMain"));
const IndustriesPages = lazy(() =>
  import("./Pages/IndustriesPages/IndustriesPages")
);
const Contact = lazy(() => import("./Pages/ContactUs/Contact"));
const Manufacturing = lazy(() =>
  import("./Pages/Industries/Manufacturing/Manufacturing")
);
const WebThreeArticles = lazy(() =>
  import("./components/HomeArticles/WebThreeArt/WebThreeArt")
);
const Cloudintegration = lazy(() =>
  import("./components/HomeArticles/Cloudintegration/Cloudintegration")
);
const ErpCrmSymphony = lazy(() =>
  import("./components/HomeArticles/ErpCrmSymphony/ErpCrmSymphony")
);
const MobileHome = lazy(() =>
  import("./components/CarouselHome/MobileHomePage/MobileHomePage")
);

function App() {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const showFooter = location.pathname !== "/"; // Conditionally show footer
  const addPadding = location.pathname !== "/"; // Conditionally add padding

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="app">
      <ScrollToTop />
      <Navbar />
      <div className={addPadding ? "app-top-padding" : ""}>
        <Routes>
          <Route path="/" element={<Suspense fallback={<Loader />}> <Home /></Suspense> }/>
          <Route path="/experience-design" element={ <Suspense fallback={<Loader />}> <Xd /> </Suspense>} />
          <Route
            path="/cloud-applications"
            element={
              <Suspense fallback={<Loader />}>
                <CA />
              </Suspense>
            }
          />
          <Route
            path="/workforce-engagement"
            element={
              <Suspense fallback={<Loader />}>
                <We />
              </Suspense>
            }
          />
          <Route
            path="/web-3"
            element={
              <Suspense fallback={<Loader />}>
                <W3 />
              </Suspense>
            }
          />
          <Route
            path="/data-centric"
            element={
              <Suspense fallback={<Loader />}>
                <DC />
              </Suspense>
            }
          />
          {/* Nested routes for industries */}
          <Route
            path="/industries"
            element={
              <Suspense fallback={<Loader />}>
                <IndustriesPages />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Banking />
                </Suspense>
              }
            />
            <Route
              path="banking&finance"
              element={
                <Suspense fallback={<Loader />}>
                  <Banking />
                </Suspense>
              }
            />
            <Route
              path="health&fitness"
              element={
                <Suspense fallback={<Loader />}>
                  <HealthFitness />
                </Suspense>
              }
            />
            <Route
              path="e-commerce&quick-commerce"
              element={
                <Suspense fallback={<Loader />}>
                  <EcomQcom />
                </Suspense>
              }
            />
            <Route
              path="entertainment&sports"
              element={
                <Suspense fallback={<Loader />}>
                  <Entertainment />
                </Suspense>
              }
            />
            <Route
              path="manufacturing"
              element={
                <Suspense fallback={<Loader />}>
                  <Manufacturing />
                </Suspense>
              }
            />
          </Route>
          {/* Nested routes for industries end */}

          <Route
            path="/erp-crm-symphony"
            element={
              <Suspense fallback={<Loader />}>
                <ErpCrmSymphony />
              </Suspense>
            }
          />
          <Route
            path="/testing"
            element={
              <Suspense fallback={<Loader />}>
                <WebThreeArticles />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/technologies"
            element={
              <Suspense fallback={<Loader />}>
                <Technologies />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<Loader />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/studios"
            element={
              <Suspense fallback={<Loader />}>
                <StudiosPage />
              </Suspense>
            }
          />
          <Route
            path="/backend-offerings"
            element={
              <Suspense fallback={<Loader />}>
                <BackendOfferings />
              </Suspense>
            }
          />
          <Route
            path="/app-platforms"
            element={
              <Suspense fallback={<Loader />}>
                <AppPlatforms />
              </Suspense>
            }
          />
          <Route
            path="/entertainment&sports"
            element={
              <Suspense fallback={<Loader />}>
                <Entertainment />
              </Suspense>
            }
          />
          <Route
            path="/health&fitness"
            element={
              <Suspense fallback={<Loader />}>
                <HealthFitness />
              </Suspense>
            }
          />
          <Route
            path="/mobile-apps"
            element={
              <Suspense fallback={<Loader />}>
                <MobileApps />
              </Suspense>
            }
          />
          <Route
            path="/banking&finance"
            element={
              <Suspense fallback={<Loader />}>
                <Banking />
              </Suspense>
            }
          />
          <Route
            path="/frontend-solutions"
            element={
              <Suspense fallback={<Loader />}>
                <Frontend />
              </Suspense>
            }
          />
          <Route
            path="/cloud-services"
            element={
              <Suspense fallback={<Loader />}>
                <Cloudservices />
              </Suspense>
            }
          />
          <Route
            path="/manufacturing"
            element={
              <Suspense fallback={<Loader />}>
                <Manufacturing />
              </Suspense>
            }
          />
          <Route
            path="/e-commerce&quick-commerce"
            element={
              <Suspense fallback={<Loader />}>
                <EcomQcom />
              </Suspense>
            }
          />
          <Route
            path="/cloud-integration"
            element={
              <Suspense fallback={<Loader />}>
                <Cloudintegration />
              </Suspense>
            }
          />
                    {/* Main Technologies Page */}
                    <Route
            path="/technologies"
            element={
              <Suspense fallback={<Loader />}>
                <Technologies />
              </Suspense>
            }
          >
            {/* Nested Routes */}
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Frontend />
                </Suspense>
              }
            />
            <Route
              path="frontend-solutions"
              element={
                <Suspense fallback={<Loader />}>
                  <Frontend />
                </Suspense>
              }
            />
            <Route
              path="backend-offerings"
              element={
                <Suspense fallback={<Loader />}>
                  <BackendOfferings />
                </Suspense>
              }
            />
            <Route
              path="mobile-apps"
              element={
                <Suspense fallback={<Loader />}>
                  <MobileApps />
                </Suspense>
              }
            />
            <Route
              path="app-platforms"
              element={
                <Suspense fallback={<Loader />}>
                  <AppPlatforms />
                </Suspense>
              }
            />
            <Route
              path="cloud-services"
              element={
                <Suspense fallback={<Loader />}>
                   <Cloudservices />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/mobile"
            element={
              <Suspense fallback={<Loader />}>
                <MobileHome />
              </Suspense>
            }
          />
          
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <Error404 />
              </Suspense>
            }
          />
        </Routes>
      </div>

      {showFooter && <Footer />}
      <Popup show={showPopup} handleClose={togglePopup} />
    </div>
  );
}

function AppWrapper() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default AppWrapper;
