
import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/LogoSup.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [linkColor, setLinkColor] = useState(false);
  const [logobg, setLogobg] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state for mobile detection
  const navbarRef = useRef();
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle("no-scroll", !isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
        setLinkColor(true);
        setLogobg(true);
      } else {
        setScrolled(false);
        setLinkColor(false);
        setLogobg(false);
      }
    };

    if (!isHomePage) {
      setScrolled(true); // Set default state for non-home pages
      setLinkColor(true);
    } else {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHomePage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setActiveSubMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav ref={navbarRef} className={`navbar ${scrolled ? "scrolled" : ""}`}>
        <Link to="/" className="navbar-logo">
          <img
            src={Logo}
            alt="logo"
            className={`supernal-logo ${
              logobg && !isOpen
                ? "supernal-logo-active"
                : "supernal-logo-active"
            }`}
          />
        </Link>
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
        <div className={`navbar-links ${isOpen ? "open" : ""}`}>
          <div className="close-btn" onClick={toggleMenu}>
            &times;
          </div>
          {/* <Link to="/" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>Home</Link> */}
          <Link
            to="/"
            className={`navbar-item ${
              location.pathname === "/" ? "active-link" : ""
            } ${linkColor && !isOpen ? "color-change-link" : ""}`}
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link
            to="/studios"
            className={`navbar-item ${
              location.pathname === "/studios" ? "active-link" : ""
            } ${linkColor && !isOpen ? "color-change-link" : ""}`}
            onClick={closeMenu}
          >
            Studios
          </Link>
          {isMobile ? (
            <div
              className={`navbar-item ${
                linkColor && !isOpen ? "color-change-link" : ""
              }`}
              onClick={() => toggleSubMenu(2)}
            >
              Industries
              <div className={`sub-menu ${activeSubMenu === 2 ? "open" : ""}`}>
                <Link to="/banking&finance" onClick={closeMenu}>
                  Banking & Finance
                </Link>
                <Link to="/e-commerce&quick-commerce" onClick={closeMenu}>
                  E-com & Q-com
                </Link>
                <Link to="/health&fitness" onClick={closeMenu}>
                  Health & Fitness
                </Link>
                <Link to="/entertainment&sports" onClick={closeMenu}>
                  Entertainment & Sports
                </Link>
                <Link to="/manufacturing" onClick={closeMenu}>
                  Manufacturing
                </Link>
              </div>
            </div>
          ) : (
            <Link
              to="/industries"
              className={`navbar-item ${
                location.pathname.startsWith("/industries") ? "active-link" : ""
              } ${linkColor && !isOpen ? "color-change-link" : ""}`}
              onClick={() => toggleSubMenu(2)}
            >
              Industries
              <div className={`sub-menu ${activeSubMenu === 2 ? "open" : ""}`}>
                <Link to="/banking&finance" onClick={closeMenu}>
                  Banking & Finance
                </Link>
                <Link to="/e-commerce&quick-commerce" onClick={closeMenu}>
                  E-com & Q-com
                </Link>
                <Link to="/health&fitness" onClick={closeMenu}>
                  Health & Fitness
                </Link>
                <Link to="/entertainment&sports" onClick={closeMenu}>
                  Entertainment & Sports
                </Link>
                <Link to="/manufacturing" onClick={closeMenu}>
                  Manufacturing
                </Link>
              </div>
            </Link>
          )}
          <Link
            to="/about-us"
            className={`navbar-item ${
              location.pathname === "/about-us" ? "active-link" : ""
            } ${linkColor && !isOpen ? "color-change-link" : ""}`}
            onClick={closeMenu}
          >
            About Us
          </Link>
          <a
            href="https://supernal-it.com/careers/"
            className={`navbar-item ${
              location.pathname === "/careers" ? "active-link" : ""
            } ${linkColor && !isOpen ? "color-change-link" : ""}`}
            onClick={closeMenu}
          >
            Careers
          </a>
          <Link className="nav-item" to="/contact-us" onClick={closeMenu}>
            <button className="CaButton">Connect</button>
          </Link>
        </div>
      </nav>
      <div
        className={`overlay-burger ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      ></div>
    </>
  );
};

export default Navbar;
