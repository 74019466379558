import React from 'react'
import "./Loader.css"

function Loader() {
  return (
    <div class="loading-page">
    <svg id="svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 200">

        <path class="cls-1"
            d="M15.5,127.4c.07-.72.15-1.17.16-1.61.48-18.87,5.28-36.54,14.74-52.89,11.38-19.67,27.22-34.75,47.27-45.38,16.52-8.75,34.24-12.65,52.87-12.7,12.79-.03,25.58-.03,38.37.03,1.69.01,2.27-.44,2.22-2.18-.11-4.22-.02-8.44-.01-12.67h-44.05c-.43.1-.86.25-1.29.28-3.75.29-7.51.45-11.25.83-21.33,2.18-40.66,9.74-58.14,22.02-15.77,11.08-28.58,24.97-38.35,41.63C8.42,81.13,2.67,98.72,1.07,117.67c-.16,1.85-.71,3.67-1.07,5.5v3.9c1.93.11,3.85.28,5.78.31,3.23.05,6.45.02,9.72.02Z" />
        <path class="cls-1"
            d="M158.38,131.49c-1.19,0-1.12.76-1.16,1.57-.12,2.59-.18,5.19-.44,7.78-1.5,14.9-5.68,29.01-12.77,42.21-7.79,14.5-18.23,26.79-31.25,36.87-14.52,11.25-30.74,18.74-48.83,22.12-11.11,2.07-22.34,2.03-33.58,2.04-8.87,0-17.75.21-26.62.34-.3,0-.59.13-1.05.25v13.97c-1.02-.08-1.85-.14-2.68-.2.52.13,1.04.38,1.56.38,18.32.02,36.64.01,54.96.01-.93-.14-1.87-.28-2.8-.41,32.88-3.23,60.86-16.37,83.36-40.76,22.42-24.3,34.46-52.79,35.19-86.14-4.81,0-9.35.01-13.89-.01Z" />
        <path class="cls-1"
            d="M171.09,33.45c0-2.54-.06-2.61-2.61-2.61-7.01,0-14.03-.03-21.04.07-9.54.14-19.14-.22-28.6.72-24.89,2.46-45.4,13.89-62.2,32.15-9.21,10.02-15.94,21.54-20.2,34.52-2.94,8.95-4.43,18.13-5.04,27.49-.03.5.53,1.5.83,1.51,4.52.1,9.05.07,13.42.07.38-2.11.82-3.96,1.03-5.83,1.76-15.56,6.99-29.8,16.48-42.3,16.22-21.36,38.08-32.18,64.66-33.51,10.11-.5,20.26-.16,30.39-.18,3.64,0,7.27.04,10.91.09,1.45.02,2.06-.52,2.01-2.09-.12-3.37-.03-6.75-.04-10.13Z" />
        <path class="cls-1"
            d="M35.47,227.99c8.77.04,17.51-.04,26.15-1.91,26.67-5.76,47.95-19.57,63.21-42.3,8.8-13.11,13.93-27.64,15.85-43.3.36-2.92.53-5.86.8-8.99-4.72,0-9.06.07-13.4-.03-1.53-.04-1.82.59-1.89,1.93-.83,17.22-6.3,32.84-16.83,46.48-15.81,20.51-36.82,31.64-62.66,33.07-11.46.63-22.98.21-34.47.26-3.1.01-6.19,0-9.36,0v14.66c.67.04,1.24.09,1.81.09,10.26,0,20.52-.03,30.78.03Z" />
        <path class="cls-1"
            d="M171.12,74.59c-.1-3.7-.08-7.4,0-11.1.03-1.27-.44-1.8-1.65-1.77-1.62.04-3.24.1-4.86.1-11.88.01-23.76-.03-35.64.02-12.74.05-24.49,3.64-35.15,10.51-14.73,9.48-24.56,22.7-29.29,39.62-1.38,4.95-2.23,9.98-2.25,15.41,4.91,0,9.51.03,14.11-.06.38,0,1.03-.87,1.08-1.38.77-7.57,2.66-14.79,6.23-21.55,5.98-11.32,14.95-19.27,26.8-24.02,7.72-3.09,15.8-4,24.05-3.97,11.55.04,23.1-.03,34.65,0,1.39,0,1.97-.32,1.93-1.83Z" />
        <path class="cls-1"
            d="M8.09,197c12.15-.04,24.29-.04,36.44-.16,8.8-.09,17.37-1.62,25.34-5.41,21.1-10.05,34.41-26.48,39.45-49.38.74-3.37.88-6.87,1.33-10.55-4.65,0-8.79.09-12.94-.04-1.75-.06-2.29.5-2.47,2.22-1.31,12.82-6.1,24.11-15.33,33.27-10.45,10.36-23.31,15.07-37.83,15.34-10.97.2-21.95.03-32.93.06-1.74,0-3.5.16-5.23.42-.43.06-1.07.78-1.08,1.2-.08,4.28-.05,8.56-.05,13.04,1.96,0,3.63,0,5.31,0Z" />
    </svg> 
 
      
</div>
  )
}

export default Loader
