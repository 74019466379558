import React from "react";
import "./Footer.css";
// import './Footer-original.css'
// import map from "../../assets/map.webp";
import { Link } from "react-router-dom";
import logo from "../../assets/LogoSup.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="TechMob Logo" className="logo" />

          </div>

          <div className="footer-sections">
            <div className="footer-links">
              <h3>Industries</h3>
              <ul>
                <li>
                  <Link to="banking&finance">
                    Banking & Finance
                  </Link>
                </li>
                <li>
                  <Link to="e-commerce&quick-commerce">
                    E-com & Q-com
                  </Link>
                </li>
                <li>
                  <Link to="health&fitness">
                    Health & Fitness
                  </Link>
                </li>
                <li>
                  <Link to="entertainment&sports">
                    Entertainment&Sports
                  </Link>
                </li>

                <li>
                  <Link to="manufacturing">
                    Manufacturing
                  </Link>
                </li>
                {/* <li><Link to="/mobile-apps">Mobile Apps</Link></li>
                <li><Link to="/mobile-apps">BackEnd Offering</Link></li>
                <li><Link to="/mobile-apps">App Platforms</Link></li>
                <li><Link to="/mobile-apps">FrontEnd Solutions</Link></li>
                <li><Link to="/mobile-apps">Cloud Services</Link></li> */}
              </ul>
            </div>

            <div className="footer-links">
              <h3>Studios</h3>

              <ul>
                <li>
                  <Link to="/experience-design">Experience Design</Link>
                </li>
                <li>
                  <Link to="/cloud-applications">Cloud Applications</Link>
                </li>
                <li>
                  <Link to="/workforce-engagement">Workforce Engagement</Link>
                </li>
                <li>
                  <Link to="/web-3">Web 3</Link>
                </li>
                <li>
                  <Link to="/data-centric">Data Centric</Link>
                </li>

              </ul>
            </div>


            <div className="footer-contact">
              <h3 className="footertitle">Locate Us</h3>
              {/* <p><i className="fas fa-map-marker-alt"></i> */}
              <p>
                India Address :
                Supernal InfoTech Pvt. Ltd.<br />
                Suit 5A, 2-48/5/6,
                5th floor Vaishnavi's Cynosure,
                Gachibowli,
                Hyderabad, Telangana-500032</p>
              <p><i className="fas fa-phone"></i> +91 9550008443</p>


              {/* <i className="fas fa-map-marker-alt">🇺🇸</i>  */}

              <p><i className="fas fa-envelope"></i>connect@supernal-it.com</p>
            </div>
            <div className="footer-contact2">

              {/* <p><i className="fas fa-map-marker-alt"></i> */}

              <p>

                {/* <i className="fas fa-map-marker-alt">🇺🇸</i>  */}

                {/* 🇺🇸 US Address :
                SUPERNAL INFOTECH LLC<br/>
                MEENA KOVOOR MBR
                3908 BLUEJAY LN NAPERVILLE,<br/> IL 60564</p> */}
                US Address :
                Supernal InfoTech<br /> LLC
                MEENA KOVOOR MBR
                3908 BLUEJAY LN NAPERVILLE,<br /> IL 60564</p>
              {/* <p><i className="fas fa-phone"></i>🇮🇳 +91 9550008443</p> */}
              <p><i className="fas fa-phone"></i> +1-206-866-5159</p>

            </div>
          </div>
        </div>

        {/* <div className="footer-map-container">
          <img src={map} alt="World Map" className="footer-map" />
        </div> */}
      </div>
      {/* <div className="footer-links">
        <h3 className="footertitle">UseFull Links</h3>
        <ul>
        <li><Link to="/mobile-apps">Mobile Apps</Link></li>
        <li><Link to="/backend-offerings">BackEnd Offering</Link></li>
        <li><Link to="/app-platforms">App Platforms</Link></li>
        <li><Link to="/frontend-solutions">FrontEnd Solutions</Link></li>
        <li><Link to="/cloud-services">Cloud Services</Link></li>
        </ul>
      </div> */}
      <div className="footer-bottom">
        <p>&copy; 2020-2025 Supernal Infotech Pvt. All Rights Reserved</p>
      </div>
     
    </footer>
  );
};

export default Footer


